import http from '../http';

import {
  InternalFeedbackResponse,
  FeedbackRole,
  CreateFeedbackRequest,
  CreateFeedbackResponse,
} from './types';

import {
  FEEDBACK_ENDPOINT,
  USER_FEEDBACK_ENDPOINT,
} from '@/constants/endpoints';

type Args = {
  role: FeedbackRole;
  sellerId: number;
  offset?: string;
  limit?: number;
};

export function fetchFeedback({ sellerId, role, offset, limit }: Args) {
  return http.get<InternalFeedbackResponse>(
    USER_FEEDBACK_ENDPOINT.replace(':sellerId', sellerId.toString()),
    {
      params: {
        role,
        offset,
        limit,
      },
    }
  );
}

export function createFeedback({
  text,
  rating,
  transactionId,
}: CreateFeedbackRequest) {
  return http.post<CreateFeedbackResponse>(
    FEEDBACK_ENDPOINT,
    {
      text,
      rating,
      transactionId,
    },
    {
      withAuth: true,
    }
  );
}
