import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

interface Props {
  id: string;
  children: React.ReactNode;
  inAppView?: boolean;
  className?: string;
}

function TabPanel({ id, className, inAppView, children }: Props) {
  return (
    <div
      id={`${id}-tab`}
      className={clsx(
        styles.tabPanel,
        {
          [styles.noVerticalPadding]: inAppView,
        },
        className
      )}
      role="tabpanel"
      aria-labelledby={`${id}-button`}
    >
      {children}
    </div>
  );
}

export { TabPanel };
