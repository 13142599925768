import React from 'react';
import { StarEmptyIcon } from '@depop/web-ui-kit/Icons/StarEmptyIcon';
import { StarHalfIcon } from '@depop/web-ui-kit/Icons/StarHalfIcon';
import { StarIcon } from '@depop/web-ui-kit/Icons/StarIcon';

import { ButtonMinimal } from '../ButtonMinimal';

import styles from './styles.module.css';

type Props = {
  rating: number;
  size?: number;
  onStarClick?: (index: number) => unknown;
};

export function FeedbackStars({ rating, size = 20, onStarClick }: Props) {
  const stars = Array(5).fill(<StarEmptyIcon size={size} title="Empty Star" />);
  const remainder = rating % 1;

  let fullStarsCount;

  if (remainder > 0.5) {
    fullStarsCount = Math.ceil(rating);
  } else {
    fullStarsCount = Math.floor(rating);
  }

  const shouldAddHalfStar = remainder <= 0.5 && remainder > 0;

  stars.fill(
    <StarIcon className={styles.stars} size={size} title="Full Star" />,
    0,
    fullStarsCount
  );

  if (fullStarsCount < 5 && shouldAddHalfStar) {
    stars.splice(
      fullStarsCount,
      1,
      <StarHalfIcon className={styles.stars} size={size} title="Half Star" />
    );
  }

  return stars.map((star, index) => {
    return onStarClick ? (
      <ButtonMinimal
        onClick={() => onStarClick && onStarClick(index)}
        key={index}
      >
        {star}
      </ButtonMinimal>
    ) : (
      <React.Fragment key={index}>{star}</React.Fragment>
    );
  });
}
