import React, { Fragment } from 'react';

import { TabPanel } from './TabPanel';

interface Props {
  children: React.ReactElement[];
  activeIndex?: number;
}

function TabPanels({ activeIndex = 0, children }: Props) {
  return <Fragment>{children[activeIndex]}</Fragment>;
}

export { TabPanel, TabPanels };
