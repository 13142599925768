type Author = {
  id: number;
  verified: boolean;
  username: string;
};

export type Feedback = {
  feedbackId: string;
  buyerId: number;
  sellerId: number;
  productId: number;
  transactionId: number;
  rating: number;
  text?: string;
  dateCreated: string;
  unread: boolean;
  deleted: boolean;
};

interface FeedbackMetaBase {
  end: boolean;
}

export interface InternalFeedbackMeta extends FeedbackMetaBase {
  next_offset?: string;
  limit: number;
}

export interface FeedbackMeta extends FeedbackMetaBase {
  cursor?: string;
  next_offset?: number;
  offset?: number;
  previous_offset?: number;
  limit?: number;
}

interface FeedbackItemBase {
  author: Author;
  date: string;
  product: {
    id: number;
    preview: {
      [key: number]: string;
    };
  };
  rating: number;
  text: string;
}

export interface InternalFeedbackItem extends FeedbackItemBase {
  transaction_id: number;
}

export interface FeedbackItem extends FeedbackItemBase {
  transactionId: number;
}

export type InternalFeedbackResponse = {
  meta: InternalFeedbackMeta;
  feedback: InternalFeedbackItem[];
  sellerId: number;
};

export type FeedbackResponse = {
  meta: FeedbackMeta;
  feedback: FeedbackItem[];
};

export type CreateFeedbackResponse = {
  id: string;
  rating: number;
  text: string;
  author: Author;
};

export enum FeedbackRole {
  Seller = 'seller',
  Buyer = 'buyer',
}

export type CreateFeedbackRequest = {
  text: string;
  rating: number;
  transactionId: number;
};
