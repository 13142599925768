import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { InternalFeedbackItem } from '@/modules/feedback/types';
import { ExtendedLink } from '@/components/ExtendedLink';
import { FeedbackStars } from '@/components/FeedbackStars';
import { getFeedbackDate } from '@/modules/feedback/helpers';
import { getLargestPictureFormatFromObject } from '@/modules/images/helpers';

type Props = InternalFeedbackItem;

export function FeedbackModalRow({
  product,
  author,
  date,
  rating,
  text,
}: Props) {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={getLargestPictureFormatFromObject(product.preview)}
        alt=""
        height={1280}
        width={1280}
      />
      <div>
        <ExtendedLink className={styles.username} href={`/${author.username}/`}>
          <Text bold>@{author.username}</Text>
        </ExtendedLink>
        <div className={styles.ratingContainer}>
          <FeedbackStars rating={rating} />
        </div>
      </div>
      <Text>{text}</Text>
      <Text type="caption1">{getFeedbackDate(date)}</Text>
    </div>
  );
}
