import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import { PrimaryTabs } from '@depop/web-ui-kit/PrimaryTabs';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';

import styles from './styles.module.css';
import { FeedbackModalRow } from './FeedbackModalRow';

import {
  RQ_FEEDBACK_BUYER,
  RQ_FEEDBACK_SELLER,
} from '@/modules/ReactQuery/cacheKeys';
import { fetchFeedback } from '@/modules/feedback/api';
import {
  FeedbackRole,
  InternalFeedbackItem,
  InternalFeedbackMeta,
} from '@/modules/feedback/types';
import { ErrorMessage } from '@/components/ErrorMessage';
import { InfiniteScroll } from '@/components/InfiniteScroll';
import { TabPanels, TabPanel } from '@/components/TabPanels';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';

type TabConfiguration = {
  id: FeedbackRole;
  value: number;
  label: string;
  hasError: boolean;
  data?: InternalFeedbackItem[];
  isSuccess: boolean;
  meta?: InternalFeedbackMeta;
  fetchNextPage: () => void;
  isLoading: boolean;
  noResultsMessage: string;
};

type Props = {
  sellerId: number;
  sellerUsername: string;
};

export function FeedbackModal({ sellerId, sellerUsername }: Props) {
  const [activeTab, setActiveTab] = useState(0);
  const tShop = useTranslations('shop');
  const tCommon = useTranslations('common');
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const isViewingOwnShop = useIsViewingOwnShop();

  useEffect(() => {
    const section = activeTab === 0 ? 'sold' : 'purchased';
    sendActivityTrackerEvent(
      ActivityTrackerEventType.USER_PROFILE_REVIEWS_VIEW,
      { section }
    );
  }, [activeTab]);

  const {
    data: sellerFeedbackData,
    isError: fetchSellerFeedbackError,
    isSuccess: fetchSellerFeedbackSuccess,
    fetchNextPage: fetchNextSellerFeedbackPage,
    isLoading: fetchSellerFeedbackLoading,
    isFetchingNextPage: isFetchingNextSellerFeedbackPage,
  } = useInfiniteQuery({
    queryKey: [RQ_FEEDBACK_SELLER, sellerId],
    queryFn: ({ pageParam }) =>
      fetchFeedback({
        role: FeedbackRole.Seller,
        sellerId,
        offset: pageParam,
      }),
    enabled: activeTab === 0,
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.data.meta.next_offset,
  });

  const {
    data: buyerFeedbackData,
    isError: fetchBuyerFeedbackError,
    isSuccess: fetchBuyerFeedbackSuccess,
    fetchNextPage: fetchNextBuyerFeedbackPage,
    isLoading: fetchBuyerFeedbackLoading,
    isFetchingNextPage: isFetchingNextBuyerFeedbackPage,
  } = useInfiniteQuery({
    queryKey: [RQ_FEEDBACK_BUYER, sellerId],
    queryFn: ({ pageParam }) =>
      fetchFeedback({
        role: FeedbackRole.Buyer,
        sellerId,
        offset: pageParam,
      }),
    enabled: activeTab === 1,
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.data.meta.next_offset,
  });

  const sellerFeedback = sellerFeedbackData?.pages?.flatMap(
    (page) => page.data.feedback
  );
  const buyerFeedback = buyerFeedbackData?.pages?.flatMap(
    (page) => page.data.feedback
  );

  const feedbackTabsDetails: TabConfiguration[] = [
    {
      id: FeedbackRole.Seller,
      value: 0,
      label: tCommon('SoldSingular'),
      hasError: fetchSellerFeedbackError,
      data: sellerFeedback,
      isSuccess: fetchSellerFeedbackSuccess,
      meta: sellerFeedbackData?.pages[sellerFeedbackData?.pages?.length - 1]
        .data.meta,
      fetchNextPage: fetchNextSellerFeedbackPage,
      isLoading: fetchSellerFeedbackLoading || isFetchingNextSellerFeedbackPage,
      noResultsMessage: isViewingOwnShop
        ? tShop('NoOwnSellingFeedback')
        : tShop('NoFeedback', { username: sellerUsername }),
    },
    {
      id: FeedbackRole.Buyer,
      value: 1,
      label: tShop('Purchased'),
      hasError: fetchBuyerFeedbackError,
      data: buyerFeedback,
      isSuccess: fetchBuyerFeedbackSuccess,
      meta: buyerFeedbackData?.pages[buyerFeedbackData?.pages?.length - 1].data
        .meta,
      fetchNextPage: fetchNextBuyerFeedbackPage,
      isLoading: fetchBuyerFeedbackLoading || isFetchingNextBuyerFeedbackPage,
      noResultsMessage: isViewingOwnShop
        ? tShop('NoOwnBuyingFeedback')
        : tShop('NoFeedback', { username: sellerUsername }),
    },
  ];

  return (
    <>
      <PrimaryTabs
        tabs={feedbackTabsDetails}
        activeValue={activeTab}
        onChange={(e) => setActiveTab(Number(e.target.value))}
        name="feedback-tabs"
      />
      <TabPanels activeIndex={activeTab}>
        {feedbackTabsDetails.map(
          ({
            id,
            hasError,
            isSuccess,
            data,
            fetchNextPage,
            meta,
            isLoading,
            noResultsMessage,
          }) => {
            const hasData = data && data.length > 0;
            const showItems = isLoading || hasData;
            return (
              <TabPanel key={id} id={id} className={styles.tabPanel}>
                {isSuccess && !data?.length && (
                  <div className={styles.message}>
                    <Text>{noResultsMessage}</Text>
                  </div>
                )}
                {hasError && (
                  <div className={styles.message}>
                    <ErrorMessage type="body">
                      {tCommon('500.Message')}
                    </ErrorMessage>
                  </div>
                )}
                {showItems && (
                  <div className={styles.items}>
                    <InfiniteScroll
                      onEndReached={fetchNextPage}
                      hasMore={!meta?.end}
                      LoadingComponent={<LoadingBall />}
                      loading={isLoading}
                    >
                      {data?.map((userRow) => (
                        <FeedbackModalRow
                          key={userRow.product.id}
                          {...userRow}
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </TabPanel>
            );
          }
        )}
      </TabPanels>
    </>
  );
}
