'use client';

import React, { useState } from 'react';
import { ButtonLink } from '@depop/web-ui-kit/ButtonLink';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Modal } from '@depop/web-ui-kit/Modal';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import { FeedbackModal } from '../FeedbackModal';

import styles from './styles.module.css';

import { FeedbackStars } from '@/components/FeedbackStars';

type Props = {
  rating: number;
  count: number;
  sellerUsername: string;
  sellerId: number;
  className?: string;
};

export function Feedback({
  rating,
  count,
  sellerUsername,
  sellerId,
  className = '',
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslations('common');

  return (
    <>
      <ButtonLink
        className={clsx(styles.feedbackButton, className)}
        onClick={() => setIsOpen(true)}
      >
        <FeedbackStars rating={rating} size={16} />
        <Text>({count})</Text>
      </ButtonLink>
      <Modal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={t('FeedbackFor', { username: sellerUsername })}
        content={() => (
          <FeedbackModal sellerId={sellerId} sellerUsername={sellerUsername} />
        )}
        wide
      />
    </>
  );
}
